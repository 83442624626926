import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styles from "./index.module.sass"
import { Lock } from '@styled-icons/boxicons-solid'

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Shodai Kayama</title>
      <meta name="robots" content="noimageindex" />
    </Helmet>

    {/*---- Headline ----*/}
    <h1 className={styles.home_headline}>
      I am Shodai Kayama.<br />
      Creative technologist & design researcher based in London and Tokyo.<br />
      I design new experience by weaving digital/analog interaction, storytelling and people’s creativity with playfulness.
    </h1>

    {/*---- Works ----*/}
    <div className={styles.works}>
      {/* @Todo Componentalise later */}
      <article className={styles.work_item}>
        <Link to="works/hangedMan">
          <div className={styles.work_item_textInfo}>
            <div className={styles.work_item_textInfo_container}>
              <h2 className={styles.work_item_title}>The Hanged Man</h2>
              <p className={styles.work_item_description}>A robotic user interface to animate clothes at the boundary between daily situation and storytelling world</p>
            </div>
          </div>
          
          <div className={styles.work_item_picture_wrapper}>
            <Image fileName={"works/hangedMan/thumbnail.jpg"} className={styles.work_item_picture} />
          </div>
        </Link>
      </article>

      <article className={styles.work_item}>
        <Link to="works/re-fit">
          <div className={styles.work_item_textInfo}>
            <div className={styles.work_item_textInfo_container}>
              <h2 className={styles.work_item_title}>Re-fit</h2>
              <p className={styles.work_item_description}>The interactive projection of replicated environment responding to observer's own motion</p>
            </div>
          </div>
          
          <div className={styles.work_item_picture_wrapper}>
            <Image fileName={"works/re-fit/thumbnail.jpg"} className={styles.work_item_picture} />
          </div>
        </Link>
      </article>

      <article className={styles.work_item}>
        <Link to="works/edibleEnvelope">
          <div className={styles.work_item_textInfo}>
            <div className={styles.work_item_textInfo_container}>
              <h2 className={styles.work_item_title}>Edible Envelope</h2>
              <p className={styles.work_item_description}>Designing a new communication interface through cooking</p>
            </div>
          </div>
          
          <div className={styles.work_item_picture_wrapper}>
            <Image fileName={"works/edibleEnvelope/thumbnail.jpg"} className={styles.work_item_picture} />
          </div>
        </Link>
      </article>


      <article className={styles.work_item}>
        <Link to="works/methodsAndProcesses">
          <div className={styles.work_item_textInfo}>
            <div className={styles.work_item_textInfo_container}>
              <h2 className={styles.work_item_title}>Methods and Processes</h2>
              <p className={styles.work_item_description}>Designing creative framework while developing interventional design for fuel poverty</p>
            </div>
          </div>
          
          <div className={styles.work_item_picture_wrapper}>
            <Image fileName={"works/methodsAndProcesses/thumbnail.jpg"} className={styles.work_item_picture} />
          </div>
        </Link>
      </article>

      <article className={styles.work_item}>
        <a href="https://drive.google.com/file/d/18xhnG0AzgGOGq3MV91T-JPu4Czilw7EU/view?usp=sharing" target="_blank">
          <div className={styles.work_item_textInfo}>
            <div className={styles.work_item_textInfo_container}>
              <h2 className={styles.work_item_title}><Lock size="20" className={styles.work_item_iconLock} /> Featured Projects from Client Works</h2>
              <p className={styles.work_item_description}>e-learning system, social game, live broadcasting platform, etc.</p>
            </div>
          </div>
          
          <div className={styles.work_item_picture_wrapper}>
            <Image fileName={"works/oldWorks/thumbnail.jpg"} className={styles.work_item_picture} />
          </div>
        </a>
      </article>


    </div>



{
    // <p>Welcome to your new Gatsby site.</p>
    // <p>Now go build something great.</p>
    // <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
    //   <Image />
    // </div>
    // <Link to="/page-2/">Go to page 2</Link>
}

  </Layout>
)

export default IndexPage
